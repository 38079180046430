<template>
    <div class="section-page">
        <div class="container section-page__container">
            <aside class="section-page__sidebar">
                <div class="section-page__sidebar-inner">
                    <nav class="section-page__nav section-nav section-nav_layout_column">
                        <ul class="section-nav__list">
                            <li class="section-nav__item" v-for="(airplaneItem, index) of airplanes" :key="index">
                                <router-link
                                    exact-active-class="section-nav__link_active"
                                    :to="{
                                        name: 'Service',
                                        params: { lang: $i18n.locale, serviceName: airplaneItem.img },
                                    }"
                                    class="section-nav__link link"
                                    >{{ airplaneItem.title }}</router-link
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
            <transition name="fade">
                <div class="section-page__content airplane-detail" v-show="fadeAirplaneInfo">
                    <template v-if="service">
                        <h1 class="airplane-detail__title section-title">{{ service.title }}</h1>
                        <p class="airplane-detail__description">{{ service.description }}</p>
                        <div class="airplane-detail__slider detail-slider">
                            <ImgAnimation class="detail-slider__img-wrapper">
                                <img
                                    class="detail-slider__img"
                                    :src="require(`@/assets/images/services/${service.img}/detail.jpg`)"
                                    alt=""
                                />
                            </ImgAnimation>
                        </div>
                        <template v-if="service.news">
                            <div
                                class="airplane-detail__message message"
                                v-for="(newItem, index) of service.news"
                                :key="index"
                            >
                                <h3 class="message__title">{{ newItem.title['ru'] }}</h3>
                                <template v-if="newItem.description">
                                    <p class="message__description" v-if="!Array.isArray(newItem.description['ru'])">
                                        {{ newItem.description['ru'] }}
                                    </p>
                                    <template v-else>
                                        <p
                                            class="message__description"
                                            v-for="(paragraph, index) in newItem.description['ru']"
                                            :key="index + paragraph"
                                        >
                                            {{ paragraph }}
                                        </p>
                                    </template>
                                </template>
                                <ul class="message__list" v-if="newItem.list">
                                    <li
                                        class="message__list-item"
                                        v-for="(listItem, index) of newItem.list"
                                        :key="index"
                                    >
                                        <AppIcon class="message__list-icon" name="star" width="12" height="12" />
                                        {{ listItem['ru'] }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <div class="airplane-detail__message message">
                            <div class="message__link">
                                <router-link
                                    :to="{
                                        name: 'Airplanes',
                                        params: { lang: $i18n.locale },
                                    }"
                                    class="message__link large-link"
                                >
                                    <span v-html="$t('service-page.appeal')" />
                                    <AppIcon class="large-link__icon" name="arrow-right" width="57" height="17" />
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <h1 class="airplane-detail__title section-title">{{ $t('service-page.404') }}</h1>
                    </template>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
const airplaneData = require('./services.json')

export default {
    name: 'Service',
    components: {},
    data() {
        return {
            fadeAirplaneInfo: true,
            service: null,
        }
    },
    computed: {
        airplanes() {
            return airplaneData
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler: function (to) {
                this.fadeAirplaneInfo = false
                setTimeout(() => {
                    this.fadeAirplaneInfo = true
                    this.service = airplaneData.find((service) => service.img === to.params.serviceName)
                }, 500)
            },
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 500ms;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
